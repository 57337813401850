import { ethers } from 'ethers';
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import { WalletConnectParams } from './types';
import { connectProvider, isRequestableProvider } from './rpc';

const ERRORS = {
  PROVIDER_NOT_FOUND: 'The Wallet Connect provider was not found',
};

export async function connect({
  projectId,
  chains,
  showModal,
}: WalletConnectParams): Promise<ethers.providers.Web3Provider> {
  const provider = await EthereumProvider.init({
    projectId,
    chains,
    optionalChains: [1],
    showQrModal: showModal,
    // qrModalOptions: {
    //   themeVariables: {
    //     '--w3m-accent-color': '#00BB7F',
    //   },
    // },
  });

  if (!isRequestableProvider(provider)) {
    throw new Error(ERRORS.PROVIDER_NOT_FOUND);
  }

  try {
    await provider.connect();
  } catch (e) {
    console.error('Error connecting with WalletConnect provider');
  }

  await connectProvider(provider, chains[0]);

  return new ethers.providers.Web3Provider(provider);
}
