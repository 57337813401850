import { useAccount, useSignMessage } from 'wagmi';
import styles from './SignButton.module.css';
import { toast } from 'react-toastify';

export function SignButton() {
  const { isConnected } = useAccount();
  const { signMessageAsync } = useSignMessage({ message: 'PQ Authentication Web3Modal!' });

  async function onSignMessage() {
    try {
      const signature = await signMessageAsync();
      console.log(`Signature done: ${signature}`);
      toast.success(`The sign message was ${signature}`, {
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch {
      console.log(`Error Sign`);
      toast.error('Failed to sign message', {
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <>
      {isConnected ? (
        <button className={styles.classicButton} onClick={() => onSignMessage()}>
          Sign Message
        </button>
      ) : (
        <button className={styles.classicButton} onClick={() => onSignMessage()}>
          Sign Message v2
        </button>
      )}
    </>
  );
}
