import {
  createWeb3Modal,
  defaultWagmiConfig,
  useWeb3Modal,
  useWeb3ModalEvents,
  useWeb3ModalState,
  useWeb3ModalTheme,
} from '@web3modal/wagmi/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WagmiConfig } from 'wagmi';
import { sepolia, mainnet } from 'wagmi/chains';
import { SignButton } from './components/SignButton';
import { WalletConnectIMXProvider } from './providers/walletConnectWrapper';

import { provider, config } from '@imtbl/sdk';

// const genIMXProv = provider.GenericIMXProvider;

// genIMXProv

// // @ts-expect-error 1. Get projectId
// const projectId = import.meta.env.VITE_PROJECT_ID
// if (!projectId) {
//   throw new Error('VITE_PROJECT_ID is not set')
// }

// 1. Get projectId
const projectId = '9fedd1f8793ba26eedfd49cd311431b7';

// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal PQ',
  description: 'PQ Web3Modal',
  url: 'https://planetquest.io',
  icons: ['https://pqps-dev.fra1.cdn.digitaloceanspaces.com/favicon.ico'],
};

// 2. Create wagmiConfig
const chains = [sepolia, mainnet];
const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

// 3. Create modal
const walletConnectProvider = createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeMode: 'light',
  themeVariables: {
    '--w3m-color-mix': '#00DCFF',
    '--w3m-color-mix-strength': 20,
  },
});

const wrapperMetaMaskConnect = async (modal: boolean) => {
  const walletConnectIMXProvider = await WalletConnectIMXProvider.connect(
    new provider.ProviderConfiguration({
      baseConfig: new config.ImmutableConfiguration({
        environment: config.Environment.SANDBOX,
        // environment: config.Environment.PRODUCTION,
      }),
    }),
    {
      projectId: '9fedd1f8793ba26eedfd49cd311431b7', // <<<<<< add your Wallet Connect project // 841 in marketplace
      // const projectId = '9fedd1f8793ba26eedfd49cd311431b7'; in one of my wallet tests
      // chains: [1],
      chains: [11155111], //sefolia chainId
      showModal: modal,
    },
  );
  console.log('walletConnectIMXProvider');
  console.log(walletConnectIMXProvider);

  debugger;
  console.log('After debugger');
  try {
    const alreadyRegistered = await walletConnectIMXProvider.isRegisteredOffchain();
    console.log('alreadyRegistered');
    console.log(alreadyRegistered);
    // Not the best approach, but just for testing is fine. Their own api doesn't handle the response correctly
    // You get a 404 with a response:
    //   {
    //     "code": "account_not_found",
    //     "message": "Account not found: 0x98ujasdxxxxxxxxxxxx"
    // }
  } catch (error) {
    console.log('Error');
    console.log(error);
    const registerUser = await walletConnectIMXProvider.registerOffchain();
    console.log('registerUser');
    console.log(registerUser);
  }
};

export default function App() {
  // 4. Use modal hook
  const modal = useWeb3Modal();
  const state = useWeb3ModalState();
  const { themeMode, themeVariables, setThemeMode, setThemeVariables } = useWeb3ModalTheme();
  const events = useWeb3ModalEvents();

  console.log(walletConnectProvider);

  return (
    <WagmiConfig config={wagmiConfig}>
      <ToastContainer />
      <>Main Button that has all the functionality directly</>
      <w3m-button />
      <>Network Button</>
      <w3m-network-button />
      <>Connect Button</>
      <w3m-connect-button />
      <>Account Button</>
      <w3m-account-button />

      <button
        onClick={
          async () => await wrapperMetaMaskConnect(false)
          // async () => await provider.GenericIMXProvider.prototype.isRegisteredOffchain()
          // WalletConnectIMXProvider.registerIMXUser(provider.GenericIMXProvider.prototype)
        }
      >
        registerIMXUser false Modal
      </button>

      <button
        onClick={
          async () => await wrapperMetaMaskConnect(true)
          // async () => await provider.GenericIMXProvider.prototype.isRegisteredOffchain()
          // WalletConnectIMXProvider.registerIMXUser(provider.GenericIMXProvider.prototype)
        }
      >
        registerIMXUser true Modal
      </button>

      <button onClick={() => modal.open()}>Connect Wallet</button>
      <button onClick={() => modal.open({ view: 'Networks' })}>Choose Network</button>
      <button onClick={() => setThemeMode(themeMode === 'dark' ? 'light' : 'dark')}>
        Toggle Theme Mode
      </button>
      <button
        onClick={() =>
          setThemeVariables({ '--w3m-color-mix': '#00DCFF', '--w3m-color-mix-strength': 20 })
        }
      >
        Toggle Theme Light
      </button>
      <button
        onClick={() =>
          setThemeVariables({ '--w3m-color-mix': '#00BB7F', '--w3m-color-mix-strength': 40 })
        }
      >
        Toggle Theme Dark
      </button>
      <></>
      <SignButton />
      <pre>{JSON.stringify(state, null, 2)}</pre>
      <pre>{JSON.stringify({ themeMode, themeVariables }, null, 2)}</pre>
      <pre>{JSON.stringify(events, null, 2)}</pre>
    </WagmiConfig>
  );
}
