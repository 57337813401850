import { Web3Provider } from '@ethersproject/providers';
import { connect } from './walletConnect';
import { connect as buildImxSigner, disconnect as disconnectImxSigner } from '../utils/imxWallet';
import { provider } from '@imtbl/sdk';
import { ImxSigner } from '../utils/ImxSigner';
import { ProviderError, ProviderErrorType, withProviderError } from '../utils/providerError';
import { WalletConnectParams } from './types';

export class WalletConnectIMXProvider extends provider.GenericIMXProvider {
  private static imxSigner: ImxSigner;

  private static wcProvider: Web3Provider;

  public static async connect(
    config: provider.ProviderConfiguration,
    walletConnectConfig: WalletConnectParams,
  ): Promise<WalletConnectIMXProvider> {
    return await withProviderError<WalletConnectIMXProvider>(
      async () => {
        const walletConnectProvider = await connect(walletConnectConfig);
        this.imxSigner = await buildImxSigner(walletConnectProvider, config.baseConfig.environment);
        this.wcProvider = walletConnectProvider;
        return new WalletConnectIMXProvider(
          config,
          walletConnectProvider.getSigner(),
          this.imxSigner,
        );
      },
      { type: ProviderErrorType.WALLET_CONNECTION_ERROR },
    );
  }

  public static async disconnect(): Promise<void> {
    if (!this.imxSigner) {
      throw new ProviderError(
        'Attempted to disconnect from the WalletConnectIMX provider without an established connection',
        ProviderErrorType.PROVIDER_CONNECTION_ERROR,
      );
    }

    await (this.wcProvider.provider as any).disconnect();

    return withProviderError<void>(
      async () => {
        await disconnectImxSigner(this.imxSigner);
      },
      { type: ProviderErrorType.PROVIDER_CONNECTION_ERROR },
    );
  }

  public static async signMessage(message: string): Promise<string> {
    if (!this.imxSigner) {
      throw new ProviderError(
        'Attempted to sign a message with the WalletConnectIMX provider without an established connection',
        ProviderErrorType.PROVIDER_CONNECTION_ERROR,
      );
    }

    return withProviderError<string>(async () => await this.imxSigner.signMessage(message), {
      type: ProviderErrorType.PROVIDER_CONNECTION_ERROR,
    });
  }

  // // constructor(config: ProviderConfiguration$1, ethSigner: Signer, starkSigner: StarkSigner);
  // public static async registerIMXUser(provider: provider.IMXProvider, test: WalletConnectIMXProvider): Promise<boolean> {
  //   console.log(`registerIMXUser`);
  //   const st = test.
  //   const alreadyRegistered = await provider.isRegisteredOffchain();
  //   console.log(`alreadyRegistered ${alreadyRegistered}`);
  //   if (!alreadyRegistered) {
  //     console.log(`Inside not alreadyRegistered`);
  //     await provider.registerOffchain();
  //   }
  //   return true;
  //   // return withProviderError<string>(async () => await this.imxSigner.registerOffchain(message), {
  //   //   type: ProviderErrorType.PROVIDER_CONNECTION_ERROR,
  //   // });
  // }
}
